<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">

            <NavAccounts/>

            <v-card class="rounded-lg shadow mt-7">

              <v-row>

                <v-col cols="3">
                  <NavListAccounts/>
                </v-col>

                <v-col cols="9">

                  <v-card-text>
                    <div class="text-end">
                      <v-btn class="rounded-lg"
                             color="gifty"
                             dark
                             @click="$refs.mailSettingFormDialog.open()"
                             depressed
                      >
                        <v-icon left>mdi-plus</v-icon>
                        Ajouter un config
                      </v-btn>
                    </div>
                  </v-card-text>


                  <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                    ></v-progress-circular>
                  </v-overlay>


                  <v-card-text v-if="isLoading">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>
                    <v-card-text>
                      <v-simple-table v-if="filteredMailSettings.length" class="table-border rounded-lg">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Modèle par défaut</th>
                            <th>Mail Driver</th>
                            <th>Mail Host</th>
                            <th>Mail Port</th>
                            <th>Mail Username</th>
                            <th>Mail Encryption</th>
                            <th>Mail From Address</th>
                            <th>Mail From Name</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="(mail ,index) in filteredMailSettings" :key="index">

                            <td>
                              <v-icon color="success" v-if="mail.is_default">mdi-check-circle</v-icon>
                              <v-icon v-else color="red">mdi-close-circle</v-icon>
                            </td>
                            <td>{{ mail.mail_driver }}</td>
                            <td>{{ mail.mail_host }}</td>
                            <td>{{ mail.mail_port }}</td>
                            <td>{{ mail.mail_username }}</td>
                            <td>{{ mail.mail_encryption }}</td>
                            <td>{{ mail.mail_from_address }}</td>
                            <td>{{ mail.mail_from_name }}</td>

                            <td>
                              <div class="text-no-wrap">
                                <v-btn icon @click="$refs.mailSettingFormDialog.open(mail)">
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>

                                <v-btn icon color="red"
                                       @click="remove(mail.id)">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </div>
                            </td>

                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getMailSettings"/>
                      </div>
                    </v-card-text>

                  </div>

                </v-col>

              </v-row>


            </v-card>
          </v-col>
        </v-row>
      </div>

      <MailSettingFormDialog ref="mailSettingFormDialog" @refresh="getMailSettings"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import NavAccounts from "@/views/accounts/components/NavAccounts";
import MailSettingFormDialog from "@/views/accounts/mailSettings/MailSettingFormDialog";
import NavListAccounts from "@/views/accounts/components/NavListAccounts";

export default {
  components: {NavListAccounts, MailSettingFormDialog, NavAccounts, NoResults},
  data() {
    return {
      id: '',
      overlay: false,
      isLoading: false,
      mailSettings: [],
    };
  },
  methods: {
    getMailSettings() {
      this.isLoading = true
      HTTP.get('/v1/email-configs').then(res => {
        this.mailSettings = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/email-configs/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.mailSettings.findIndex((el) => el.id === this.id);
        this.$delete(this.mailSettings, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  computed: {
    filteredMailSettings() {
      return this.mailSettings.length ? this.mailSettings.filter(mail => mail.id !== null) : [];
    }
  },
  created() {
    this.getMailSettings();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    }
  },

};
</script>

<style>
</style>
