<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-checkbox v-model="form.is_default"
                      label="Modèle par défaut"
                      :error-messages="errors.is_default"
          ></v-checkbox>


          <v-text-field outlined
                        dense
                        v-model="form.mail_driver"
                        :error-messages="errors.mail_driver"
                        label="Mail Driver *"
          />

          <v-text-field outlined
                        dense
                        v-model="form.mail_host"
                        :error-messages="errors.mail_host"
                        label="Mail Host *"
          />

          <v-text-field outlined
                        dense
                        v-model="form.mail_port"
                        :error-messages="errors.mail_port"
                        label="Mail Port *"
          />

          <v-text-field outlined
                        dense
                        v-model="form.mail_username"
                        :error-messages="errors.mail_username"
                        label="Mail Username *"
          />

          <v-text-field outlined
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        dense
                        v-model="form.mail_password"
                        :error-messages="errors.mail_password"
                        label="Mail Password *"
          />

          <v-text-field outlined
                        dense
                        v-model="form.mail_encryption"
                        :error-messages="errors.mail_encryption"
                        label="Mail Encryption *"
          />

          <v-text-field outlined
                        dense
                        v-model="form.mail_from_address"
                        :error-messages="errors.mail_from_address"
                        label="Mail From Address *"
          />


          <v-text-field outlined
                        dense
                        v-model="form.mail_from_name"
                        :error-messages="errors.mail_from_name"
                        label="Mail From Name *"
                        hide-details="auto"
          />

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg"
                 :loading="isLoading"
                 :disabled="isLoading"
                 color="primary"
                 @click="handleSave"
                 depressed
          >
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      title: null,
      showPassword: false,
      dialog: false,
      isLoading: false,
      form: {
        is_default: false
      },
      errors: {},
    }
  },
  methods: {
    open(config) {
      this.dialog = true
      this.errors = {}
      this.title = config ? "Modifier  un config" : "Ajouter un config"
      this.form = config ?? {
        is_default: false
      }
    },
    close() {
      this.dialog = false
    },
    handleSave() {
      this.errors = {}
      this.isLoading = true
      let url = this.form.id ? "/v1/email-configs/update" : "/v1/email-configs/store"
      HTTP.post(url, this.form).then(() => {
        this.isLoading = false
        this.$successMessage = "Cet élément a été enregistré avec succès.";
        this.close();
        this.$emit("refresh");
      }).catch(err => {
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
        this.isLoading = false
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>